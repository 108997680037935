.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
}

.switchButtonContainer {
    max-width: 285px;
    height: 45px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.utilContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    max-width: 100px;
}
.copyright {
    position: fixed;
    font-size: xx-small;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    margin-left: calc(100vw - 58%);
    bottom: 1%;
}
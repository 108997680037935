.upload {
    text-align: center;
    font-family: Avenir;
    display: inline-block;
    width: 100%;
    /* margin-left: 10%; */
    margin-top: 5%;
    /* padding: 15px; */
}

.upload-btn,
.template-btn {
    margin-right: 10px;
}

.input_fastq,
.input_seq,
.input_client,
.input_add,
.input_seq_meta,
.input_prod_meta,
.input_ana_meta {
    display: none;
}

.selector_drop {
    display: flex;
    place-content: center;
}

.selector {
    margin-right: 10px;
}

.progress_bar {
    width: 800px;
    margin-left: 35%;
}

.helpful {
    font-size: 12px;
    font-family: Avenir;
}
.header {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* align-content: center; */
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.menu {
    display: flex;
    align-items: center;
}

.logo {
    width: 110px;
    margin-top: 20px;
    z-index: 1;
}

.menuIcon {
    cursor: pointer;
    margin-right: 10px;
}

.user {
    margin-left: auto;
    margin-right: 0;
}

.pathogen-selector {
    z-index: 5;
    display: grid;
    background-color: #fff;
    /* width: auto; */
    flex-direction: column;
}

.pathogen-selector-toggle {
    display: grid;
    margin-right: 5px;
}

.toggle-checkbox {
    margin-right: 8px;
}